import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postEmployeeLogin } from "../../Redux/siteWideSettingsSlice";

function AELogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postEmployeeLogin(email, password));
  };

  return (
    <>
      <div className="container">
        <h2 className="text-center mt-4">Employee Login</h2>
        <div className="card mx-auto mt-5" style={{ maxWidth: "400px" }}>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">User Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">Login</button>
            </form>
            <a href="https://remote.max.maxhat.com/TcpSalesCenterV3/ResetPassword" 
               className="black-link text-decoration-none hover-underline"
               target="_blank"
               rel="noopener noreferrer">
              Forgot Password?
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AELogin;
