import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import Loading from "./components/loading";
import "./App.css";
import { useMediaQuery } from "react-responsive";
import { getCookieByName } from "./CustomFunctions/MyFunctions";
import YouAreSpecial from "./pages/YouAreSpecial.js";
import NotFound from "./pages/errors/NotFound";
import ErrorBoundary from "./components/Error/ErrorBoundary.js";
import ErrorMessage from './pages/errors/ErrorMessage';
import { setActiveDate, getWebsiteSettings, setEmployeeUser } from '../src/Redux/siteWideSettingsSlice';
import PageViewTracker from '../src/components/GoogleAnalytics/PageViewTracker';
import EmployeesBanner from "./pages/EmployeesBanner";
import EmployeeInfo from "../src/pages/EmployeeInfo/EmployeeInfo";
import EmployeeInfoLogin from "../src/pages/EmployeeInfo/EmployeeInfoLogin";
import { allowCrawling, getActiveImageServer } from "../src/Redux/siteWideSettingsSlice";
import AEHome from "../src/pages/Employee/AE/Home";
import LoginShell from "../src/pages/Customer/LoginShell";
import EmployeeAuth from "./components/Employee/Auth.js";
import EmployeeInfoAuth from "./components/EmployeeInfo/InfoAuth.js";
import Customers from "./pages/Employee/AE/Customers.js";
import Customer from "./pages/Employee/AE/Customer.js";
import User from "./pages/Employee/AE/User.js";
import Users from "./pages/Employee/AE/Users.js";
import CustomerAuth from "../src/components/Customer/CustomerAuth.js";
import CustomerShell from "../src/pages/Customer/CustomerShell.js";
import Settings from "./pages/settings.js";
import PageMeta from "./components/PageMeta.js";
import MfaLogin from "./components/Customer/MfaLogin.js";
import { ApiCall } from "./components/ApiCall.js";
import TcpMaxDatabase from "./components/TcpMaxDatabase.js";
import CustomerAssistance from "./pages/CustomerAssistance.js";
import ContactForm from "../src/components/ContactForm.js";
import CookieConsent from "./components/CookieConsent";
import Catalog from "./pages/catalog.js";
import SageProduct from "./pages/SageProduct";
import PrintOrder from "./pages/Customer/PrintOrder";

const Navbar = React.lazy(() => import("./components/HeaderMain/navbar"));
const Home = React.lazy(() => import("./pages/home"));
const Products = React.lazy(() => import("./pages/products"));
// const Product = React.lazy(() => import("./pages/product"));
const Footer = React.lazy(() => import("./components/footer"));
// const Cart = React.lazy(() => import("./pages/cart"));
// const Checkout = React.lazy(() => import("./pages/checkout"));
// const Clearance = React.lazy(() => import("./pages/clearance"));
const Blog = React.lazy(() => import("./pages/blog"));
const Reroute = React.lazy(() => import("./components/Routing/reroute"));
const Promotionals = React.lazy(() => import("./pages/promotionals"));
// const Promotionals = React.lazy(() => import("./pages/promotionals"));
const { setEmployeeInfoProfileLoggedIn } = require("./Redux/siteWideSettingsSlice");

const App = () => {
  //TODO site store maintenane script can be moved to its own component
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  const showNavbar = useSelector((state) => state.siteWideSettings.showNavbar);
  const showFooter = useSelector((state) => state.siteWideSettings.showFooter);
  const activeDate = useSelector((state) => state.siteWideSettings.activeDate);
  const testDateActive = useSelector((state) => state.siteWideSettings?.EmployeeUser?.TestDateActive);
  const employeeUserDataExpiration = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.EmployeeUserDataExpiration);
  const employeeLoggedIn = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.LoggedIn);
  const ID = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.ID);
  const userDate = useSelector((state) => state.siteWideSettings.activeDate);
  const websiteExpiration = useSelector((state) => state?.siteWideSettings?.websiteVersion?.expiration);
  const customerAccount = useSelector((state) => state?.customer?.account);
  const customerAccounts = useSelector((state) => state?.customer?.accounts);

  useEffect(() => {
    async function fetch() {
      dispatch(getActiveImageServer());
    };

    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (!testDateActive === true) {
      const currentDate = new Date().toISOString().substring(0, 10);
      if (currentDate > activeDate) {
        dispatch(setActiveDate(currentDate));
      }
    }
  }, [dispatch, activeDate, testDateActive, userDate]);

  useEffect(() => {
    const employeeAuthenticated = JSON.parse(localStorage.getItem('employeeAuthenticated'));
    if (employeeAuthenticated) {
      dispatch(setEmployeeInfoProfileLoggedIn(true));
    }
  }, [dispatch]);

  useEffect(() => {
    const checkExpirationsAndUpdateUsers = async () => {
      const currentTime = Date.now();

      if (typeof websiteExpiration === 'undefined' || currentTime > websiteExpiration) {
        await dispatch(getWebsiteSettings());
      }

      if (employeeLoggedIn === true && currentTime > employeeUserDataExpiration) {
        const data = { 'ID': ID };
        const response = await ApiCall('usp_SC_Users', data, process.env.REACT_APP_DATABASE);
        const loggedInEmployee = {
          ...response?.data?.Table[0],
          LoggedIn: true,//TODO: make this for all user settings instead of just LoggedIn
        };
        await dispatch(setEmployeeUser(loggedInEmployee));
      }
    };

    checkExpirationsAndUpdateUsers();
    // 5 minutes
    const interval = setInterval(checkExpirationsAndUpdateUsers, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, websiteExpiration, ID, employeeLoggedIn, employeeUserDataExpiration]);

  useEffect(() => {
    if (!window.location.pathname.startsWith("/products")) {
      allowCrawling();
    }
  }, [navigate]);

  const SageProductRoute = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sproductid = searchParams.get('sproductid');
    return sproductid ? <SageProduct /> : <Reroute />;
  };

  return (
    <ErrorBoundary componentName="App" fallback={<ErrorMessage />}>
      <div className="App">
        <CookieConsent />
        <Suspense fallback={<Loading />}>
          <PageMeta />
          <PageViewTracker />
          {showNavbar && (
            <Navbar isDesktop={isDesktop} />
          )}
          <div className="page-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="youarespecial" element={<YouAreSpecial />} />
              {/* <Route path="employeesbanner" element={<EmployeesBanner />} /> TODO Delete this and the components it uses unless it is determined that this is something we actually want. */}
              <Route path="promotionals" element={<Promotionals />} />
              <Route path="employeeinformation/login" element={<EmployeeInfoLogin />} />
              <Route path="addedit/login" element={<AEHome pageName="aelogin" />} />
              <Route path="ae/login" element={<AEHome pageName="aelogin" />} />
              <Route path="contact-form" element={<ContactForm />} />

              <Route path="customer-assistance/catalog" element={<Catalog />} />
              <Route path="customer-assistance/:selection" element={<CustomerAssistance />} />
              <Route path="customer-assistance" element={<Navigate to="about-us" />} />

              <Route path="customer">
                <Route path="login" element={<LoginShell pageName="login" />} />
                <Route path="login/mfa" element={<MfaLogin />} />
                <Route path="passwordreset" element={<LoginShell pageName="passwordreset" />} />
                <Route path="passwordconfirmation/:resetrowguid" element={<LoginShell pageName="passwordconfirmation" />} />
                <Route path="signup/:EmailRowGuid?" element={<LoginShell pageName="signup" />} />
                <Route path="emailconfirmation/:resetrowguid" element={<LoginShell pageName="emailconfirmation" />} />
              </Route>

              <Route element={<EmployeeAuth />}>
                <Route path="employee/Customers" element={<Customers />} />
                <Route path="employee/Users" element={<Users />} />
                <Route path='employee/Customer/:CustID' element={<Customer />} />
                <Route path='employee/User/:ID/:CustomerID?' element={<User />} />
                <Route path="settings" element={<Settings />} />
              </Route>

              <Route element={<EmployeeInfoAuth />}>
                <Route path="employeeinformation" element={<EmployeeInfo lang="" />} />
                <Route path="employeeinformation/spa" element={<EmployeeInfo lang="spa" />} />
                <Route path="employeeinformation/eng" element={<EmployeeInfo lang="eng" />} />
              </Route>

              <Route element={<CustomerAuth />}>
                <Route path="customer">
                  <Route path="orders/:RowGuid?/:EmailRowGuid?" element={<CustomerShell />} />
                  <Route path="order/:RowGuid?/:EmailRowGuid?" element={<CustomerShell />} />
                  <Route path="invoices/:RowGuid?/:EmailRowGuid?" element={<CustomerShell />} />
                  <Route path="invoice/:RowGuid?/:EmailRowGuid?" element={<CustomerShell />} />
                  <Route path="unpaidinvoices/:RowGuid?/:EmailRowGuid?" element={<CustomerShell />} />
                  <Route path="unpaidinvoice/:RowGuid?/:EmailRowGuid?" element={<CustomerShell />} />
                  <Route path="profile" element={<CustomerShell />} />
                  <Route path="taxexemption" element={<CustomerShell />} />
                  <Route path="print/:RowGuid?/:EmailRowGuid?" element={<PrintOrder />} />
                  <Route path="paymentmethod" element={<CustomerShell />} />
                  <Route path="support" element={<CustomerShell />} />
                  <Route path="managelogos" element={<CustomerShell />} />
                  <Route path="savedcarts" element={<CustomerShell />} />
                  <Route path="accounts"
                    element={(!customerAccount && customerAccounts.length > 1) ? (
                      <LoginShell />
                    ) : (
                      <Navigate to='/home' replace />
                    )}
                  />
                  <Route path="security" element={<CustomerShell />} />
                  <Route path="mfapreferences" element={<CustomerShell />} />
                </Route>
              </Route>
              <Route path="products/:url" element={<Products websitePageName="" webOrderID={getCookieByName("WebOrderID") || 0} />} />
              <Route path="products" element={<Products websitePageName="" webOrderID={getCookieByName("WebOrderID") || 0} />} />
              {/* <Route path="product/:url" element={<Product websitePageName="" isDesktop={isDesktop} />} /> */}

              {/* <Route path="cart" element={<Cart />} /> */}
              {/* <Route path="checkout" element={<Checkout websitePageName="" />} /> */}


              {/* TODO: We need a Logic to decide which page to show based on the url basically when the URL ends in a paramater.  Test on selected attributes conatining only AttributeID: 14 in dtSelectedAttributes*/}

              <Route path="404-not-found" element={<NotFound />} />

              {/* Sage Product route */}
              <Route path='product' element={<SageProductRoute />} />
              <Route path='product.aspx' element={<SageProductRoute />} />

              {/* ### This route * will go into more condition based routing */}
              <Route path="*" element={<Reroute />} />

              {/* ### Routes below should be commented out. If the route should be active it should be moved above the Reroute */}

              <Route path="blog/:pageName?" element={<Blog />} />
              {/* <Route path="blog" element={<Blog />} /> */}
              {/* <Route path="clearance" element={<Clearance />} /> */}
              {/* <Route path="designproduct" element={<DesignProduct />} /> */}
            </Routes>
          </div>
          {showFooter && (<Footer />)}
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};

export default App;
