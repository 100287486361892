import axios from 'axios';
import { ApiCallUrl } from '../config/apiconfig';
import {
  cryptAPIKey, encryptUsingAES256Base64, decryptUsingAES256
} from './Security/crypt';
import { LogError } from './Error/LogError';

export const ApiCall = async (StoredProcedure, data, database = null, timeout = null, tries = null, logError = true) => {
  try {
    data['StoredProcedure'] = StoredProcedure;

    //database values (tcp, max, remotedb, itsm)
    if (database === null) {
      data['Database'] = 'sqlCon'
    }
    else {
      data["Database"] = database;
    }

    if (timeout !== null) {
      data['Timeout'] = timeout;
    }

    if (tries !== null) {
      data['Tries'] = tries;
    }

    const requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    };

    const encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(ApiCallUrl, encryptedData, { headers: requestHeaders, timeout: 10000 });

    return { data: response.data, error: null };
  } catch (error) {
    if (logError) {
      LogError(
        { ...error, stack: error?.stack, message: error?.message + ', Data: ' + JSON.stringify(data) + ', Timeout: ' + timeout + ', Tries: ' + tries },
        'ApiCall - ' + StoredProcedure,
        'ApiCall'
      );
    }
    return { data: null, error: `An error occurred: ${error.message}` };
  }
};