import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ApiCall } from "./../ApiCall.js";
import SendEmail from "./../SendEmail.js";
import useEmailAlertsSettings from "../../Hooks/useEmailAlertsSettings";
import { VerifyRecaptcha } from '../Security/RecaptchaUtil.js';

const ContactUs = () => {
  const [sendLoading, setSendLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [viewState, setViewState] = useState('form');
  const [errorVisible, setErrorVisible] = useState(false);
  const AlertSettings = useEmailAlertsSettings(12);
  const { salesRepID: defaultSalesRepID, firstUserName: defaultUserName } = useEmailAlertsSettings(48);
  const [formData, setFormData] = useState({
    organizationName: '',
    contactName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    phone: '',
    email: '',
    comments: ''
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRecaptchaChange = async (token) => {
    const response = await VerifyRecaptcha(token);

    if (response?.error || response?.data?.results !== "Successful") {
      setViewState('error');
      return;
    }

    if (response?.data?.results === "Successful") {
      setIsCaptchaVerified(true);
      setFormErrors(prev => ({
        ...prev,
        captcha: null
      }));
    }
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.contactName || formData.contactName.trim() === "") {
      errors.contactName = "Contact name is required.";
    }

    if (!formData.address1 || formData.address1.trim() === "") {
      errors.address1 = "Address 1 is required.";
    }

    if (!formData.city || formData.city.trim() === "") {
      errors.city = "City is required.";
    }

    if (!formData.state || formData.state === "") {
      errors.state = "Please select a state.";
    }

    if (!formData.postalCode || formData.postalCode.trim() === "") {
      errors.postalCode = "Postal code is required.";
    }

    if (!formData.country || formData.country === "") {
      errors.country = "Please select a country.";
    }

    if (!formData.phone || formData.phone.trim() === "") {
      errors.phone = "Phone number is required.";
    }

    if (!formData.email || formData.email.trim() === "") {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (containsWebsiteUrl(formData.comments)) {
      errors.comments = "Sorry, URLs are not allowed in the comments.";
    }

    if (!isCaptchaVerified) {
      errors.captcha = "Please complete the reCAPTCHA verification.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const containsWebsiteUrl = (comment) => {
    const urlPattern = /(www\.|https?:\/\/)\S+/;
    return urlPattern.test(comment);
  }

  const formatMatchTable = (matches, websiteContactID) => {
    let matchTableHtml = "<table><tr><td>CustID</td><td>ContactID</td><td>CustName</td><td>Invoices</td><td>Orders</td><td>Quotes</td><td>Other</td><td>RepName</td><td>Match</td><td></td></tr>";
    matches.forEach(match => {
      if (!match.BestMatch) {
        matchTableHtml += `<tr><td>${match.CustID}</td><td>${match.ContactID}</td><td>${match.CustName}</td><td>${match.Invoices}</td><td>${match.Orders}</td><td>${match.Quotes}</td><td>${match.Other}</td><td>${match.RepName}</td></td><td></td><td></td></tr>`;
      } else {
        let links = "<a href='https://remote.max.maxhat.com/TcpSalesCenterV2/MailSubscription?WebsiteContactID=" + websiteContactID + "'>BAD MATCH</a>";

        if (match.ContactID > 0) {
          links += "<br /><a href='https://remote.max.maxhat.com/TcpSalesCenterV2/MailSubscription?ContactID=" + match.ContactID + "'>Search Customers</a>";
        }
        matchTableHtml += `<tr><td><b>${match.CustID}</b></td><td><b>${match.ContactID}</b></td><td><b>${match.CustName}</b></td><td><b>${match.Invoices}</b></td><td><b>${match.Orders}</b></td><td><b>${match.Quotes}</b></td><td><b>${match.Other}</b></td><td><b>${match.RepName}</b></td><td><b>X</b></td><td><b>${links}</b></td></tr>`;
      }
    });
    matchTableHtml += "</table>";
    return matchTableHtml;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSendLoading(true);

    if (!validateForm()) {
      setErrorVisible(true);
      setSendLoading(false);
      return;
    }
    setErrorVisible(false);

    let messageHtml = "<tr><td><b>Organization Name</b></td><td>" + formData?.organizationName?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Contact Name</b></td><td>" + formData?.contactName?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Address 1</b></td><td>" + formData?.address1?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Address 2</b></td><td>" + formData?.address2?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>City</b></td><td>" + formData?.city?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>State</b></td><td>" + formData?.state?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Postal Code</b></td><td>" + formData?.postalCode?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Country</b></td><td>" + formData?.country?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Phone</b></td><td>" + formData?.phone?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Email</b></td><td>" + formData?.email?.trim() + "</td></tr>";
    messageHtml += "<tr><td>&nbsp;</td><td>&nbsp;</td></tr>";
    messageHtml += "<tr><td><b>Questions/Comments</b></td><td>" + formData?.comments?.trim() + "</td></tr>";
    messageHtml = "<html><table>" + messageHtml + "</table></html>";

    let subject = "Triple Crown Product Contact Us Submission from " + formData.email.trim();

    var data = {
      "CustName": formData?.organizationName?.trim() || "",
      "Contact": formData?.contactName?.trim(),
      "Address1": formData?.address1?.trim(),
      "Address2": formData?.address2?.trim() || "",
      "City": formData?.city?.trim(),
      "State": formData?.state?.trim(),
      "Country": formData?.country?.trim(),
      "Zip": formData?.postalCode?.trim(),
      "Phone": formData?.phone?.trim(),
      "Email": formData?.email?.trim(),
      "Website": "",
      "EmailSubject": subject,
      "EmailMessage": messageHtml,
      "DefaultUserName": defaultUserName,
      "DefaultSalesRepID": defaultSalesRepID,
      "Source": "Contact Us",
      "OptionalCustID": "",
      "OptionalContactID": "",
      "FollowUpSalesRepID": "",
    };

    const response = await ApiCall('tq_Contact_Us_V2', data, 'TCP');

    if (response?.error) {
      setViewState('error');
      return;
    } else {
      setViewState('thankYou');
    }

    let matches = response?.data?.Table2;
    let matchTable = "";
    let bestMatch = response?.data?.Table3?.[0];
    let followUpContact = response?.data?.Table4?.[0];
    let websiteContactID = response?.data?.Table5?.[0]?.WebsiteContactID;
    let duplicate = response?.data?.Table6?.[0]?.Duplicate;
    let matchedCustID = bestMatch?.CustID;
    if (matchedCustID.toLowerCase().includes('c')) {
      matchedCustID = "";
    }
    let repEmail = bestMatch?.RepEmail;
    let followUpRepEmail = followUpContact?.FollowUpRepEmail;

    if (matches.length > 0) {
      matchTable = formatMatchTable(matches, websiteContactID);
    }

    if (!duplicate) {
      if (!response?.error) {
        if (formData?.email?.trim() != "" && formData?.email != null && formData?.email != undefined) {
          SendEmail(formData?.email.trim(), "", "COPY: " + subject, messageHtml, "auto-mate@triplecrownproducts.com");
        }

        const closingTagsIndex = messageHtml.lastIndexOf('</table></html>');
        const temp = messageHtml.slice(0, closingTagsIndex) +
          "<tr><td><b>Matches</b></td><td>" + matchTable + "</td></tr>" +
          messageHtml.slice(closingTagsIndex);
        messageHtml = temp;

        if (repEmail == "" && AlertSettings?.emailTo.length < 1 && AlertSettings?.emailCc.length < 1) {
          SendEmail("DEVTeam@triplecrownproducts.com", "", "ERROR: No Email Found - " + subject,
            "There is no rep email or alert settings email to send this contact to." + messageHtml, "auto-mate@triplecrownproducts.com");
        } else {
          if (repEmail != AlertSettings?.firstEmailTo) {
            SendEmail(repEmail, "", subject, messageHtml, formData?.email);
          }
          SendEmail(AlertSettings?.emailTo.join(','), AlertSettings?.emailCc.join(','), subject, messageHtml, "auto-mate@triplecrownproducts.com");
        }
      } else {
        SendEmail(followUpRepEmail, "DEVTeam@triplecrownproducts.com", subject, messageHtml + "<b>ERROR ADDING TO DATABASE: " + response?.error + "</b>", "info@triplecrownproducts.com");
      }
    }
  };

  return (
    <div>
      <div className="row m-3">
        <div className="col-12">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <b>Option #1) PHONE</b><br />
          <a href='tel:+1-800-619-1110'>800-619-1110</a><br />
          M-F 8AM-5:30PM CST
          <br />
          <br />
          <b>Option #2) MAIL</b><br />
          102 W. Boxhorn Dr.<br />
          Mukwonago, WI 53149
          <br />
          <br />
        </div>
        <div className="col-sm-8" style={{ borderLeft: '1px solid #c7c7c7' }}>
          <b>Option #3) ONLINE</b><br />
          {viewState === 'thankYou' && (
            <div className="thank-you-message">
              <strong>Thank you for contacting us!</strong>
              <p>
                Your questions/comments have been sent and someone will respond within one business day.
                For immediate assistance please call <a href='tel:+1-800-619-1110'>800-619-1110</a> anytime Monday through Friday between 8AM and 5:30PM CST.
              </p>
            </div>
          )}

          {viewState === 'error' && (
            <div className="alert alert-danger">
              An error occurred while submitting your contact form. <br />
              Please call us so we can assist you or try again later.
            </div>
          )}

          {viewState === 'form' && (
            <form onSubmit={handleSubmit} noValidate className="contact-form">
              {errorVisible && <div className="alert alert-danger">Please complete all required fields before proceeding.</div>}

              <div className="form-group row mb-3">
                <label htmlFor="organizationName" className="col-sm-3 col-form-label">Organization Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="organizationName"
                    id="organizationName"
                    value={formData.organizationName}
                    onChange={handleChange}
                    className={`form-control ${formErrors.organizationName ? 'is-invalid' : ''}`}
                    placeholder="Your organizations name.."
                    maxLength="30"
                  />
                  {formErrors.contactName && <div className="invalid-feedback">{formErrors.organizationName}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="contactName" className="col-sm-3 col-form-label">Contact Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="contactName"
                    id="contactName"
                    value={formData.contactName}
                    onChange={handleChange}
                    className={`form-control ${formErrors.contactName ? 'is-invalid' : ''}`}
                    placeholder="Your name.."
                    maxLength="25"
                  />
                  {formErrors.contactName && <div className="invalid-feedback">{formErrors.contactName}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="address1" className="col-sm-3 col-form-label">Address 1</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="address1"
                    id="address1"
                    value={formData.address1}
                    onChange={handleChange}
                    className={`form-control ${formErrors.address1 ? 'is-invalid' : ''}`}
                    placeholder="Your street address, P.O. box, company name, c/o.."
                    maxLength="30"
                  />
                  {formErrors.address1 && <div className="invalid-feedback">{formErrors.address1}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="address2" className="col-sm-3 col-form-label">Address 2</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="address2"
                    id="address2"
                    value={formData.address2}
                    onChange={handleChange}
                    className={`form-control ${formErrors.address2 ? 'is-invalid' : ''}`}
                    placeholder="Your apartment, suite, unit, building, floor, etc. (optional).."
                    maxLength="60"
                  />
                  {formErrors.address2 && <div className="invalid-feedback">{formErrors.address2}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="city" className="col-sm-3 col-form-label">City</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleChange}
                    className={`form-control ${formErrors.city ? 'is-invalid' : ''}`}
                    placeholder="Your city.."
                    maxLength="30"
                  />
                  {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="state" className="col-sm-3 col-form-label">State</label>
                <div className="col-sm-8">
                  <select
                    name="state"
                    id="state"
                    value={formData.state}
                    onChange={handleChange}
                    className={`form-control ${formErrors.state ? 'is-invalid' : ''}`}
                  >
                    <option value="">Select a state</option>
                    <option value="AL">Alabama</option><option value="AK">Alaska</option><option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option><option value="CA">California</option><option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option><option value="DE">Delaware</option><option value="FL">Florida</option>
                    <option value="GA">Georgia</option><option value="HI">Hawaii</option><option value="ID">Idaho</option>
                    <option value="IL">Illinois</option><option value="IN">Indiana</option><option value="IA">Iowa</option>
                    <option value="KS">Kansas</option><option value="KY">Kentucky</option><option value="LA">Louisiana</option>
                    <option value="ME">Maine</option><option value="MD">Maryland</option><option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option><option value="MN">Minnesota</option><option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option><option value="MT">Montana</option><option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option><option value="NH">New Hampshire</option><option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option><option value="NY">New York</option><option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option><option value="OH">Ohio</option><option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option><option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option><option value="SD">South Dakota</option><option value="TN">Tennessee</option>
                    <option value="TX">Texas</option><option value="UT">Utah</option><option value="VT">Vermont</option>
                    <option value="VA">Virginia</option><option value="WA">Washington</option><option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option><option value="WY">Wyoming</option>
                  </select>
                  {formErrors.state && <div className="invalid-feedback">{formErrors.state}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="postalCode" className="col-sm-3 col-form-label">Postal Code</label>
                <div className="col-sm-8">
                  <input
                    type="numeric"
                    name="postalCode"
                    id="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                    className={`form-control ${formErrors.postalCode ? 'is-invalid' : ''}`}
                    placeholder="Your postal code.."
                    maxLength="10"
                  />
                  {formErrors.postalCode && <div className="invalid-feedback">{formErrors.postalCode}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="country" className="col-sm-3 col-form-label">Country</label>
                <div className="col-sm-8">
                  <select
                    name="country"
                    id="country"
                    value={formData.country}
                    onChange={handleChange}
                    className={`form-control ${formErrors.country ? 'is-invalid' : ''}`}
                  >
                    <option value="">Select a country</option>
                    <option value="USA">USA</option>
                  </select>
                  {formErrors.country && <div className="invalid-feedback">{formErrors.country}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="phone" className="col-sm-3 col-form-label">Phone</label>
                <div className="col-sm-8">
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`}
                    placeholder="Your Phone Number.."
                    maxLength="15"
                  />
                  {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
                <div className="col-sm-8">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                    placeholder="Your Email Address.."
                    maxLength="200"
                  />
                  {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="comments" className="col-sm-3 col-form-label">Questions/Comments</label>
                <div className="col-sm-8">
                  <textarea
                    name="comments"
                    id="comments"
                    value={formData.comments}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Your questions/comments..."
                    maxLength="500"
                  ></textarea>
                  {formErrors.comments && <div className="invalid-feedback">{formErrors.comments}</div>}
                </div>
              </div>
              <div className="form-group row mb-3">
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <ReCAPTCHA
                    sitekey='6LdiEMcUAAAAAEp8xqgc2DCZ1nesdCicB1ofPpSR'
                    onChange={handleRecaptchaChange}
                    onExpired={() => setIsCaptchaVerified(false)}
                  />
                  {formErrors.captcha && <div className="text-danger mt-2">{formErrors.captcha}</div>}
                </div>
              </div>
              <div className="form-group row mb-4">
                <div className="col-sm-8">
                  <label htmlFor="submit" className="col-sm-4 col-form-label"></label>
                  <button type="submit" className="btn btn-primary btn-block OrangeButton">
                    {!sendLoading &&
                      <>
                        <span>Submit</span>
                      </>
                    }
                    {sendLoading &&
                      <>
                        <span style={{ paddingRight: '10px' }}>Submit</span>
                        <i className="fa fa-spinner fa-spin " />
                      </>
                    }
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

