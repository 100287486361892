import React, { useState, useEffect } from "react";
import { FaSpinner } from 'react-icons/fa';
import ErrorBoundary from "../Error/ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import PasswordStrengthMeter from "../PasswordStrengthMeter";
import useEmailFormatValidator from "../../Hooks/useEmailValidation";
import { ApiCall } from "../ApiCall";
import SendEmail from "../SendEmail";
import ReactDOMServer from 'react-dom/server';
import SignUpTemplate from '../EmailTemplates/SignUp';
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";
import { useDispatch } from "react-redux";
import { useParams, Link, useLocation } from 'react-router-dom';
import { LogError } from "../Error/LogError";

function SignupCustomer() {
  const { EmailRowGuid } = useParams()
  const dispatch = useDispatch();
  const location = useLocation();
  const redirectUrl = new URLSearchParams(location.search).get('redirect');
  const newCustomer = !redirectUrl;
  const getEmailValidationState = useEmailFormatValidator();
  const initialEmail = location.state?.email || '';
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState(initialEmail);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [results, setResults] = useState('');
  const [resultsMessage, setResultsMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);
  const emailValidationState = getEmailValidationState(email);
  dispatch(updatePageMeta("Customer Signup"));

  const fetchAccountInfo = async (guid) => {
    if (guid) {
      const data = {
        EmailRowGuid: guid,
        WebsiteID: 1
      };
      const response = await ApiCall('usp_Tcp_EmailRowGuid', data, 'tcp');
      if (response?.data?.Table?.length > 0) {
        setEmail(response.data.Table[0]["EmailAddress"]);
        return;
      }
    }
  };

  useEffect(() => {
    if (EmailRowGuid) {
      fetchAccountInfo(EmailRowGuid);
    }
  }, [EmailRowGuid]);

  const handleSignup = async () => {
    setNameError('');
    setEmailError('');
    setPasswordError('');
    setPasswordMatchError('');
    setResultsMessage('');

    const nameElement = document.getElementById('name');
    if (nameElement) {
      nameElement.style.border = '1px solid #ced4da';
    }

    document.getElementById('email').style.border = '1px solid #ced4da';
    document.getElementById('password').style.border = '1px solid #ced4da';
    document.getElementById('confirmPassword').style.border = '1px solid #ced4da';

    let isValid = true;

    if (nameElement && (firstName.trim().length <= 1 || lastName.trim().length <= 1)) {
      nameElement.style.border = '1px solid red';
      setNameError('Name must be more than one character.');
      isValid = false;
    } else if (nameElement && (firstName.trim().length > 25 || lastName.trim().length > 25)) {
      nameElement.style.border = '1px solid red';
      setNameError('Name must be less than 50 characters.');
      isValid = false;
    }

    if (!emailValidationState.isValid) {
      document.getElementById('email').style.border = '1px solid red';
      setEmailError(emailValidationState.message);
      isValid = false;
    }

    if (newPassword.trim() === '') {
      document.getElementById('password').style.border = '1px solid red';
      setPasswordError('Password is required.');
      isValid = false;
    }

    if (newPassword !== confirmNewPassword || confirmNewPassword.trim() === '') {
      document.getElementById('confirmPassword').style.border = '1px solid red';
      setPasswordMatchError('Passwords do not match.');
      isValid = false;
    }

    if (isValid) {
      setLoading(true);
      setResetRequested(true);
      try {
        var data = {
          'Email': email,
          'NewPassword': newPassword
        }

        if (newCustomer) {
          data['FirstName'] = firstName;
          data['LastName'] = lastName;
        }

        const response = await ApiCall('usp_Tcp_AccountSignUp', data)
        setResults(response?.data?.Table[0]["Results"]);
        setResultsMessage(response?.data?.Table[0]["ResultsMessage"]);
        if (response?.data?.Table[0]['Results'] === 'Successful') {
          const EmailBody = ReactDOMServer.renderToString(
            <SignUpTemplate resetPasswordURL={`${window.location.origin}${process.env.PUBLIC_URL}/customer/emailconfirmation/${response?.data?.Table[0]['ResetRowGuid']}${redirectUrl ? `?redirect=${redirectUrl}` : ''}`} />
          );
          await SendEmail(email, '', 'Triple Crown Products Email Confirmation', EmailBody, 'noreply@triplecrownproducts.com');
        }
      } catch (error) {
        await LogError(error.message, 'Customer Signup', 'SignupCustomer');
        setResultsMessage('An error occurred during signup');
      } finally {
        setResetRequested(false);
        setLoading(false);
      }
    }
  };

  return (
    <ErrorBoundary componentName="CustomerSignup" fallback={<ErrorMessage />}>
      <div className="forms-container positioning">
        {results !== 'Successful' && (
          <div className="text-center">
            <h2>{newCustomer ? 'Sign Up' : 'Set your account password'}</h2>
          </div>
        )}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              {results === 'Successful' ? (
                <div className="text-center p-4">
                  <h4 className="fw-bold mb-3">Account Created Successfully</h4>
                  <p className="mb-3">Thank you for creating an account with Triple Crown Products.</p>
                  <p className="mb-2">We've sent a confirmation email to <strong>{email}</strong>. Please check your inbox and follow the instructions to verify your email address.</p>
                  <br /><br />
                  <p className="small text-muted">Note: If you don't see the email in your inbox, please check your spam folder.</p>
                </div>
              ) : (
                <>
                  {newCustomer && (
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">NAME:</label>
                      <div className="input-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Enter your first name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          disabled={resetRequested}
                        />
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Enter your last name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          disabled={resetRequested}
                        />
                      </div>
                      {nameError && <div className="error-message">{nameError}</div>}
                    </div>
                  )}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">EMAIL:</label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} autoComplete="username"
                      disabled={resetRequested}
                    />
                    {emailError && <div className="text-danger">{emailError}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">PASSWORD:</label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      placeholder="Enter your password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)} autoComplete="new-password"
                      disabled={resetRequested}
                    />
                    {passwordError && <div className="text-danger">{passwordError}</div>}
                    {newPassword.length > 0 && <PasswordStrengthMeter password={newPassword} />}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">CONFIRM PASSWORD:</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      className="form-control"
                      placeholder="Confirm your password"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)} autoComplete="new-password"
                      disabled={resetRequested}
                    />
                    {passwordMatchError && <div className="text-danger">{passwordMatchError}</div>}
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={handleSignup}
                    disabled={loading}>
                    {loading ? (
                      <>
                        Signing Up... Please Wait
                        <FaSpinner className="fa-spin" style={{ marginLeft: "5px", animation: "spin 1s linear infinite" }} />
                      </>
                    ) : (
                      "CREATE ACCOUNT"
                    )}
                  </button>
                  <div className="text-center mt-5">
                    <Link
                      to={`/customer/login${redirectUrl ? `?redirect=${redirectUrl}` : ''}`}
                      className="text-dark text-decoration-none hover-underline"
                    >
                      Already have an account? Login here
                    </Link>
                  </div>
                  <div className="login-group mt-5 d-flex justify-content-center align-items-center">
                    {results === 'Failed' && (
                      <div className="alert alert-warning w-100 text-center">
                        {resultsMessage}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default SignupCustomer;
