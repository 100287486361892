import React, { useState, useEffect } from 'react';
import DynamicTabs from './DynamicTabs';
import OrangeCheckBox from '../../Utils/OrangeCheckBox';
import { ApiCall } from '../ApiCall';
import FormattedDate from '../DataManipulation/FormatDate';
import FormattedCurrency from '../DataManipulation/FormatCurrency';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../loading';
import MakePayment from '../Customer/MakePayment';
import { useParams, Link } from 'react-router-dom';
import { LogError } from '../Error/LogError';
import Paginations from '../Paginations';
import { Account } from '../../Redux/customerSlice';

import '../../styles/Orders.scss';

function Orders({ OrderType }) {
  const { RowGuid } = useParams()
  const Customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [Orders, setOrders] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [ordersChecked, setOrdersChecked] = useState(Array(Orders.length).fill(false));
  const [sortedOrders, setSortedOrders] = useState([]);
  const [AccountBalance, setAccountBalance] = useState(0);
  const [SelectedBalances, setSelectedBalances] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredOrder, setFilteredOrder] = useState(null);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      setError(null);
      try {
        var data = {
          'OrderType': OrderType,
          'WebsiteId': '1',
          'WebsiteUserId': Customer?.websiteUserID,
          'CustID': Customer?.account
        };

        if (!Customer?.account) {
          setOrders([]);
          return;
        }

        const response = await ApiCall('usp_Webstore_Orders', data, 'Tcp');

        if (response.error) {
          setError('An error occurred while fetching orders. Please try again later.');
        } else {
          const fetchedOrders = response?.data?.Table || [];
          setOrders(fetchedOrders);
          const sortedByNewest = [...fetchedOrders].sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));
          setSortedOrders(sortedByNewest);
          const balanceSum = response?.data?.Table.reduce((acc, order) => acc + parseFloat(order.BalanceDue), 0);
          setAccountBalance(balanceSum);
          setSelectedBalances(balanceSum);
        }
      } catch (error) {
        setError('An error occurred. Please try again later.');
        LogError(error, 'Orders', 'Orders');
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrders();
  }, [OrderType, Customer?.websiteUserID, Customer?.account]);

  useEffect(() => {
    const fetchCustomerData = async () => {
      const data = {
        'CustRowGuid': RowGuid
      };
      const response = await ApiCall('usp_Tcp_CustRowGuid', data, 'Tcp');
      if (response?.data?.Table?.[0]) {
        const AccountFound = Customer?.accounts.filter(account => account.CustID === response.data.Table[0].CustId)[0];
        if (AccountFound) {
          await dispatch(Account(AccountFound));
        }
      }
    };

    fetchCustomerData();
  }, [RowGuid]);

  const handlePayInvoice = (orderIndex) => {
    const selectedOrder = sortedOrders[orderIndex];
    setSortedOrders([selectedOrder]);
    setFilteredOrder(selectedOrder.OrderNum);
    setOrdersChecked([true]);
    setSelectedBalances(parseFloat(selectedOrder.BalanceDue));
  };

  const handleSelectAll = (isChecked) => {
    setIsAllChecked(isChecked);
    setOrdersChecked(Array(Orders.length).fill(isChecked));

    if (isChecked) {
      const totalSelectedBalance = Orders.reduce((acc, order) => acc + parseFloat(order.BalanceDue), 0);
      setSelectedBalances(totalSelectedBalance);
    } else {
      setSelectedBalances(0);
    }
  };

  const handleOrderCheck = (index, isChecked) => {
    const newOrdersChecked = [...ordersChecked];
    newOrdersChecked[index] = isChecked;
    setOrdersChecked(newOrdersChecked);
    setIsAllChecked(newOrdersChecked.every(Boolean));
    const selectedOrders = Orders.filter((_, index) => newOrdersChecked[index]);
    if (selectedOrders.length > 0) {
      setSelectedBalances(selectedOrders.reduce((acc, Order) => acc + parseFloat(Order.BalanceDue), 0));
    } else {
      setSelectedBalances(AccountBalance);
    }
  };

  const handleSort = (e) => {
    const sortBy = e.target.value;
    let sorted;

    switch (sortBy) {
      case 'date-oldest':
        sorted = [...Orders].sort((a, b) => new Date(a.OrderDate) - new Date(b.OrderDate));
        break;
      case 'date-newest':
        sorted = [...Orders].sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));
        break;
      default:
        sorted = [...Orders];
    }

    setSortedOrders(sorted);
  };

  const clearFilter = () => {
    setSortedOrders(Orders);
    setFilteredOrder(null);
    setOrdersChecked(Array(Orders.length).fill(false));
    setIsAllChecked(false);
    setSelectedBalances(AccountBalance);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: 'instant' });
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const paginatedOrders = sortedOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <DynamicTabs />
      {isLoading && <Loading />}
      {error && (
        <div className="alert alert-danger d-flex justify-content-center mt-5 p-3">
          {error}
        </div>
      )}
      {!isLoading && !error && sortedOrders.length === 0 && (
        <div className="d-flex justify-content-center mt-5 vh-100">
          {OrderType === 'Invoices' && 'There are currently no invoices available.'}
          {OrderType === 'UnpaidInvoices' && 'There are currently no unpaid invoices available.'}
          {OrderType === 'Orders' && 'There are currently no orders available.'}
        </div>
      )}
      {!isLoading && !error && sortedOrders.length > 0 && (
        <>
          <div className="select-sort-filter-wrapper d-flex justify-content-between align-items-center">
            {filteredOrder ? (
              <div className="filter-info alert alert-info d-flex justify-content-between align-items-center mb-3">
                <span>Filtered by Order Number: {filteredOrder}</span>
                <button className="btn btn-outline-secondary btn-sm" onClick={clearFilter}>Clear Filter</button>
              </div>
            ) : (
              <div className="select-invoice">
                <OrangeCheckBox label={"Select All"} onChange={handleSelectAll} isChecked={isAllChecked} />
              </div>
            )}
            <div className="sort-filter ms-auto d-flex align-items-center">
              {sortedOrders.length > 50 && (
                <div className="d-flex align-items-center me-3">
                  <span className="me-2">Per Page:</span>
                  <select className="form-select" onChange={handleItemsPerPageChange} value={itemsPerPage}>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                </div>
              )}
              <div className="d-flex align-items-center">
                <span className="me-2">Sort By:</span>
                <select className="form-select" onChange={handleSort} defaultValue="date-newest">
                  <option value="date-oldest">Oldest</option>
                  <option value="date-newest">Newest</option>
                </select>
              </div>
            </div>
          </div>
          <div className="orders-container">
            {paginatedOrders.map((Order, index) => (
              <div className="order-row" key={`${index}`}>
                <div className="checkbox-container">
                  <OrangeCheckBox
                    onChange={(isChecked) => handleOrderCheck(index, isChecked)}
                    isChecked={ordersChecked[index]}
                  />
                </div>
                <div className="order-card">
                  <div className="order-details">
                    <p className="order-key">Order Number:</p> <p className="order-value-text">{Order?.OrderNum}</p>
                    <p className="order-key">Order Date:</p> <p className="order-value-text"><FormattedDate date={Order?.OrderDate} dateFormat='MM/dd/yyyy' /></p>
                    <p className="order-key">Order Preview:</p> <p className="order-value-text">{Order?.ItemCount}</p>
                    <p className="order-key">Order Status:</p> <p className="order-value-text">{Order?.OrderStatus}</p>
                  </div>
                  <div className="order-summary">
                    <p className="order-key">Subtotal:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.SubTotal} /></p>
                    <p className="order-key">Shipping:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.Shipping} /></p>
                    <p className="order-key">Taxes:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.Taxes} /></p>
                    <p className="order-key">Payments:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.Payments} /></p>
                    <p className="order-key">Balance Due:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.BalanceDue} /></p>
                  </div>
                  <div className="order-actions">
                    <Link className="view-order-btn" to={`/customer/${OrderType === 'Orders' ? 'order' : OrderType === 'Invoices' ? 'invoice' : 'unpaidinvoice'}/${Order?.RowGuid}`}>
                      {OrderType === 'Orders' ? 'View Order' : 'View Invoice'}
                    </Link>
                    {OrderType !== 'Orders' && parseFloat(Order?.BalanceDue) > 0 && (
                      <div className="pay-invoice-btn" onClick={() => handlePayInvoice(index)}>Pay Invoice</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {(OrderType === 'Invoices' || OrderType === 'UnpaidInvoices') && (
              <>
                <div className="totals-container">
                  <p>Account Balance: <FormattedCurrency value={AccountBalance} /></p>
                </div>
              </>
            )}
          </div>

          {sortedOrders.length > itemsPerPage && (
            <Paginations
              activePage={currentPage}
              totalItemsCount={sortedOrders.length}
              itemsCountPerPage={itemsPerPage}
              handlePageChange={handlePageChange}
            />
          )}

          {(OrderType === 'Invoices' || OrderType === 'UnpaidInvoices') && (
            <>
              {AccountBalance > 0 && (
                <MakePayment Amount={SelectedBalances} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Orders;
