import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { ApiCall } from '../ApiCall';
import Loading from '../loading';

const CustomerAuth = () => {
  const authTokenCustomer = useSelector((state) => state?.customer?.isLoggedIn);
  const location = useLocation();
  const [accountExists, setAccountExists] = useState(null);
  const [emailAddress, setEmailAddress] = useState('');

  const getEmailRowGuidFromPathname = (pathname) => {
    if (pathname && pathname.includes('/customer/invoice/')) {
      const parts = pathname.split('/');
      return parts.length > 4 ? parts[4] : null;
    }
    return null;
  };

  const fetchAccountInfo = async (guid) => {
    if (guid) {
      const data = {
        EmailRowGuid: guid,
        WebsiteID: 1
      };
      const response = await ApiCall('usp_Tcp_EmailRowGuid', data, 'tcp');
      if (response?.data?.Table?.length > 0) {
        console.log('exists', response.data.Table[0]["AccountExists"])
        setAccountExists(response.data.Table[0]["AccountExists"]);
        setEmailAddress(response.data.Table[0]["EmailAddress"]);
        return;
      } else {
        setAccountExists(0);
        setEmailAddress('');
        return;
      }
    }
    setAccountExists(false);
    setEmailAddress('');
  };

  useEffect(() => {
    const guid = getEmailRowGuidFromPathname(location.pathname);
    if (guid) {
      fetchAccountInfo(guid);
    } else {
      setAccountExists(false);
    }
  }, [location.pathname]);

  if (accountExists === null) {
    return <Loading />;
  }

  return authTokenCustomer ? (
    <Outlet />
  ) : accountExists === 0 ? (
    <Navigate
      to={`/customer/signup?redirect=${encodeURIComponent(location.pathname)}`}
      state={{ from: location, email: emailAddress }}
      replace
    />
  ) : (
    <Navigate
      to={`/customer/login?redirect=${encodeURIComponent(location.pathname)}`}
      state={{ from: location }}
      replace
    />
  );
};

export default CustomerAuth;
