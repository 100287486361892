import React, { useEffect, useState } from 'react';
import '../styles/CustomerAssistance.scss';
import useContentById from '../Hooks/useContentById';
import { Link, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ContactUs from '../components/CustomerAssistance/ContactUs';
import EmailSubscription from '../components/CustomerAssistance/EmailSubscription';
import MailSubscription from '../components/CustomerAssistance/MailSubscription';
import Jobs from '../components/CustomerAssistance/Jobs';
import CurrentSpecial from '../components/CustomerAssistance/CurrentSpecial';
import { updatePageMeta } from '../Redux/siteWideSettingsSlice';
import { useDispatch } from 'react-redux';
import HtmlImportParser from '../components/DataManipulation/HtmlImportParser';
import Loading from '../components/loading';

function CustomerAssistance() {
  const { selection } = useParams();
  const [searchParams] = useSearchParams();
  const cs = searchParams.get('cs');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedContent, setSelectedContent] = useState('');
  const content = useContentById(cs ? '76, 54,55,56,57,143,84' : '76, 54,55,56,57,143');
  const contentItems = content?.ads;
  const showCatalogLink = content?.ads.filter(ad => ad.AdLocationID === 76)?.length > 0;
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    let resizeTimeout;

    const handleIframeMessage = (event) => {
      const trustedOrigin = window.location.port 
        ? `http://${window.location.hostname}:${window.location.port}`
        : `https://${window.location.hostname}`;

      if (event.origin !== trustedOrigin) return;

      if (event.data.type === "resizeIframe") {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          const iframe = document.getElementById("iContactForm");
          if (iframe) {
            iframe.style.height = `${event.data.height}px`;
          }
        }, 50);
      }
    };

    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
      clearTimeout(resizeTimeout); // Clean up the timeout when unmounting
    };
  }, []);

  useEffect(() => {
    if (contentItems && contentItems.length > 0) {
      setDataLoaded(true);
    }
  }, [contentItems]);

  // Function to format the name for URL and canonical purposes
  const formatNameForURL = (name) => {
    return name.replace(/\s+/g, '-').replace(/&-/g, '').toLowerCase()
      .replace('free-shipping-set-up', 'free-shipping-free-setup-first-order')
      .replace('110%-price-match', 'price-match-guarantee')
      .replace(/^decorations/, 'custom-decorations')
      .replace(/^3d-embroidery/, 'custom-3d-embroidery')
      .replace(/^embroidery/, 'custom-embroidery')
      .replace(/^screen-print/, 'custom-screen-print')
      .replace(/^flags-for-vets/, 'add-usa-flags-to-support-our-veterans')
      .replace(/^woven-loop-tags/, 'custom-woven-loop-tags')
      .replace(/^puff-screen-printing/, 'custom-puff-screen-printing')
      .replace(/^artwork\/pre-production/, 'artwork-pre-production')
      .replace(/^timeframe/, 'production-timeframe')
      .replace(/^email/, 'email-subscription')
      .replace(/^mail/, 'mail-subscription');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Effect to handle URL changes and load contentItems accordingly
  useEffect(() => {
    if (contentItems.length === 0 || !selection) return;

    const contentItem = contentItems.find(item => formatNameForURL(item.Name) === selection.toLowerCase());

    if (contentItem) {
      setSelectedContent(contentItem);
    } else if (selection !== 'current-specials') {
      navigate('/404-not-found');
    }
  }, [contentItems, location.pathname]);

  dispatch(updatePageMeta(selectedContent.Name));

  // Helper function to render submenu items
  const renderSubMenu = (items) => {
    return items.map((item, index) => (
      <div key={index} className="submenu-item">
        <Link
          to={`/customer-assistance/${formatNameForURL(item.Name)}`}
          className="submenu-link"
        >
          {item.Name}
        </Link>
      </div>
    ));
  };

  // Filter contentItems by AdLocationID
  const companyInfoContent = contentItems.filter((contentItems) => contentItems.AdLocationID === 54);
  const discountsPromosContent = contentItems.filter((contentItems) => contentItems.AdLocationID === 55);
  const subscriptionsContent = contentItems.filter((contentItems) => contentItems.AdLocationID === 143);
  const emailSubscriptionContent = subscriptionsContent.filter((contentItems) => contentItems.AdID === 35154);
  const mailSubscriptionContent = subscriptionsContent.filter((contentItems) => contentItems.AdID === 35158);
  const servicesContent = contentItems.filter((contentItems) => contentItems.AdLocationID === 56);
  const termsConditionsContent = contentItems.filter((contentItems) => contentItems.AdLocationID === 57);

  return (
    <div className="container container-ca">
      <div className="row">
        <div className="col-md-2 d-none d-md-block mb-4">
          {/* Left Div: Main Menu */}

          <div className="menu">
            <div className="menu-title">CUSTOMER ASSISTANCE</div>

            <div className="menu-category">COMPANY INFORMATION</div>
            <div className="submenu">
              {renderSubMenu(companyInfoContent)}
              <a href="https://triplecrownproducts.com/sitemap.aspx">Sitemap Navigation</a><br />
            </div>

            <div className="menu-category">DISCOUNTS/PROMOTIONS</div>
            <div className="submenu">
              {renderSubMenu(discountsPromosContent)}
            </div>

            <div className="menu-category">SUBSCRIPTIONS</div>
            <div className="submenu">
              {renderSubMenu(subscriptionsContent)}
              {showCatalogLink && <Link to="/customer-assistance/catalog">E-Catalog</Link>}
            </div>

            <div className="menu-category">SERVICES</div>
            <div className="submenu">
              {renderSubMenu(servicesContent)}
            </div>

            <div className="menu-category">TERMS & CONDITIONS</div>
            <div className="submenu">
              {renderSubMenu(termsConditionsContent)}
            </div>
          </div>
        </div>

        <div className="col-md-10 col-12" style={{ display: 'block' }}>
          {!dataLoaded ? (
            <Loading />
          ) : location.pathname === "/customer-assistance/contact-us" ? (
            <ContactUs />
          ) : location.pathname === "/customer-assistance/jobs" ? (
            <Jobs />
          ) : location.pathname === "/customer-assistance/email-subscription" ? (
            <EmailSubscription content={emailSubscriptionContent?.[0]} />
          ) : location.pathname === "/customer-assistance/mail-subscription" ? (
            <MailSubscription content={mailSubscriptionContent?.[0]} />
          ) : location.pathname === "/customer-assistance/current-specials" ? (
            <CurrentSpecial content={content} cs={cs} />
          ) : (
            <HtmlImportParser content={selectedContent?.Html || ''} /> // CMS filtered HTML content converted to JSX
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerAssistance;
