import { RecaptchaUrl } from '../../config/apiconfig';
import axios from 'axios';
import {
  cryptAPIKey, encryptUsingAES256Base64, decryptUsingAES256
} from './crypt';
import { LogError } from '../Error/LogError';

export const VerifyRecaptcha = async (token) => {
  try {  
    const data = {
      response: token
    };

    const requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    };

    const encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(RecaptchaUrl, encryptedData, { headers: requestHeaders, timeout: 30000 });

    return { data: response.data, error: null };
  } catch (error) {
    await LogError(error, 'Recaptcha Verification', 'RecaptchaUtil');
    return { data: null, error: `An error occurred: ${error.message}` };
  }
};