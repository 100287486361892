import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../Error/ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { Account } from "../../Redux/customerSlice";
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";
import "../../styles/Accounts.scss";

function Accounts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerAccounts = useSelector((state) => state?.customer?.accounts);
  dispatch(updatePageMeta("Customer Accounts"));

  const handleAccountSelect = (accountId) => {
    const AccountFound = customerAccounts.filter(account => account.CustID === accountId)[0];
    dispatch(Account(AccountFound));
    navigate(`/customer/profile`);
  };

  return (
    <ErrorBoundary componentName="CustomerAccounts" fallback={<ErrorMessage />}>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <h2 className="fw-bold fs-5 mb-4">Choose An Account:</h2>
            <div className="d-flex flex-column gap-3">
              {customerAccounts && customerAccounts.length > 0 && (
                customerAccounts.map((account) => (
                  <div
                    key={account.CustID}
                    className="account-item p-3 px-4 text-center fs-4 fw-bold rounded"
                    onClick={() => handleAccountSelect(account.CustID)}
                  >
                    {account.CustName}
                  </div>
                )))}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Accounts;
