import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../styles/ContactForm.scss';
import SendEmail from './SendEmail';
import { setShowNavbar, setShowFooter } from "../Redux/siteWideSettingsSlice";
import validateEmail from "./DataValidation/EmailValidation.js";
import { VerifyRecaptcha } from './Security/RecaptchaUtil.js';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [thankYouVisible, setThankYouVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const trustedOrigin = window.location.port ? `http://${window.location.hostname}:${window.location.port}` : `https://${window.location.hostname}`;
      for (let entry of entries) {
        window.parent.postMessage({
          type: "resizeIframe",
          height: entry.contentRect.height
        }, trustedOrigin);
      }
    });

    if (formRef.current) {
      resizeObserver.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        resizeObserver.unobserve(formRef.current);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(setShowNavbar(false));
    dispatch(setShowFooter(false));
  }, [dispatch]);

  const [formData, setFormData] = useState({
    contactName: '',
    companyName: '',
    phone: '',
    email: '',
    package: '',
    comments: ''
  });
  const [showFields, setShowFields] = useState({
    contactName: false,
    companyName: false,
    phone: false,
    email: false,
    package: false,
    comments: false
  });
  const [emailDetails, setEmailDetails] = useState({
    to: '',
    cc: '',
    subject: ''
  });


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const toEmail = searchParams.get('CFETO') || '';
    const ccEmail = searchParams.get('CFECC') || '';
    const subjectText = searchParams.get('CFESubject') || '';

    // Store values in variables first
    const isValidDomain = (email) => {
      return email.trim().toLowerCase().endsWith('@triplecrownproducts.com');
    };

    // Helper function to validate multiple emails
    const validateEmails = (emailString) => {
      if (!emailString) return true; // Empty string is valid
      const emails = emailString.split(',');
      return emails.every(email => isValidDomain(email));
    };

    // Check both to and cc emails
    const isToValid = validateEmails(toEmail);
    const isCcValid = validateEmails(ccEmail);

    if (!isToValid || !isCcValid) {
      setErrorVisible("emailError");
    } else {
      setEmailDetails({
        to: toEmail,
        cc: ccEmail,
        subject: subjectText
      });
    }

    // Determine which fields to show based on query params provided by CMS ad code
    setShowFields({
      contactName: searchParams.get('CFCName') === 'Y',
      companyName: searchParams.get('CFCompany') === 'Y',
      phone: searchParams.get('CFPhone') === 'Y',
      email: searchParams.get('CFEmail') === 'Y',
      package: searchParams.get('CFPackage') === 'Y',
      comments: searchParams.get('CFComment') === 'Y'
    });
  }, [location.search]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    let errors = {};

    // Iterate through each field in showFields
    for (const field in showFields) {
      // Check if the field is set to true (i.e., it should be shown and is required but exclude "comments")
      if (showFields[field] && field !== "comments" && field !== "email") {
        // If the corresponding formData value is empty, add it to errors
        if (!formData[field]) {
          errors[field] = `${field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} is required`; // Convert camelCase to a more readable format
        }
      } else if (showFields[field] && field === "email") {
        const emailValidation = validateEmail(formData.email);
        if (!emailValidation?.isValid) {
          errors[field] = emailValidation?.error;
        }
      }
    }

    if (!isCaptchaVerified) {
      errors.captcha = "Please complete the reCAPTCHA verification.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleRecaptchaChange = async (token) => {
    const response = await VerifyRecaptcha(token);

    if (response?.error || response?.data?.results !== "Successful") {
      setErrorVisible('recaptchaError');
      return;
    }

    if (response?.data?.results === "Successful") {
      setIsCaptchaVerified(true);
      setFormErrors(prev => ({
        ...prev,
        captcha: null
      }));
    }
  };

  const handleSubmit = async (e) => {
    setSendLoading(true);
    e.preventDefault();

    if (!validateForm()) {
      setErrorVisible("formError");
      setSendLoading(false);
      return; // Stop further processing if the form is not valid
    }

    setErrorVisible(""); // Clear shown error messages from previous submit attempt

    // Prepare email content
    let emailBody = `<h2>${emailDetails.subject}</h2><br/><table>`;
    if (showFields.contactName) emailBody += `<tr><th style="text-align: right;">Name:</th><td>${formData.contactName}</td></tr>`;
    if (showFields.companyName) emailBody += `<tr><th style="text-align: right;">Company:</th><td>${formData.companyName}</td></tr>`;
    if (showFields.phone) emailBody += `<tr><th style="text-align: right;">Phone Number:</th><td>${formData.phone}</td></tr>`;
    if (showFields.email) emailBody += `<tr><th style="text-align: right;">Email Address:</th><td>${formData.email}</td></tr>`;
    if (showFields.package) emailBody += `<tr><th style="text-align: right;">Package:</th><td>${formData.package}</td></tr>`;
    if (showFields.comments) emailBody += `<tr><th style="text-align: right;">Comments:</th><td>${formData.comments}</td></tr>`;
    emailBody += `</table>`;

    let { results } = SendEmail( // TODO once we speed up emails being sent, add "await" back in here to give useful feedback
      emailDetails.to,
      emailDetails.cc,
      emailDetails.subject,
      emailBody,
      "noreply@TripleCrownProducts.com"
    );

    results = 'Success'; // TODO similarly, once we speed up emails being sent, add "await" back in here to give useful feedback

    if (results === 'Success') {
      // Show the thank you message
      setThankYouVisible(true);
      setErrorVisible("");
    } else {
      setErrorVisible("submissionError");
    }
  };

  return (
    <div className="ccf" ref={formRef} style={{ overflow: 'hidden' }}>
      {thankYouVisible ? (
        <div className="thank-you-message"><br />
          <strong>Thank you for contacting us!</strong>
          <p>Your questions/comments have been sent, and someone will respond within one business day. For immediate assistance, please call <a href='tel:+1-800-619-1110'>800-619-1110</a> .</p>
        </div>
      ) : (
        <>
          {errorVisible === "formError" && <div className="alert alert-danger">Please complete all required fields before proceeding.</div>}
          {errorVisible === "submissionError" && <div className="alert alert-danger">There was an error submitting your form. Please try again.</div>}
          {errorVisible === "recaptchaError" && <div className="alert alert-danger">There was an error verifying the reCAPTCHA. Please call us so we can assist you or try again later.</div>}
          {errorVisible === "emailError" && <div className="alert alert-danger">Error: Invalid email in ContactForm URL.</div>}
          {errorVisible !== "emailError" && errorVisible !== "recaptchaError" && (
            <form onSubmit={handleSubmit} noValidate className="contact-form">

              {showFields.contactName && (
                <div className="form-group row">
                  <label htmlFor="contactName" className="col-sm-2 col-form-label">Contact Name</label>
                  <div className="col-12">
                    <input
                      type="text"
                      name="contactName"
                      id="contactName"
                      value={formData.contactName}
                      onChange={handleChange}
                      className={`form-control ${formErrors.contactName ? 'is-invalid' : ''}`}
                      placeholder="Your name.."
                    />
                    {formErrors.contactName && <div className="invalid-feedback">{formErrors.contactName}</div>}
                  </div>
                </div>
              )}

              {showFields.companyName && (
                <div className="form-group row">
                  <label htmlFor="companyName" className="col-sm-2 col-form-label">Company</label>
                  <div className="col-12">
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      className={`form-control ${formErrors.companyName ? 'is-invalid' : ''}`}
                      placeholder="Your Company's name.."
                    />
                    {formErrors.companyName && <div className="invalid-feedback">{formErrors.companyName}</div>}
                  </div>
                </div>
              )}

              {showFields.phone && (
                <div className="form-group row">
                  <label htmlFor="phone" className="col-sm-2 col-form-label">Phone</label>
                  <div className="col-12">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`}
                      placeholder="Your Phone Number.."
                    />
                    {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                  </div>
                </div>
              )}

              {showFields.email && (
                <div className="form-group row">
                  <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                  <div className="col-12">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                      placeholder="Your Email Address.."
                    />
                    {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                  </div>
                </div>
              )}

              {showFields.package && (
                <div className="form-group row">
                  <label htmlFor="package" className="col-sm-2 col-form-label">Package</label>
                  <div className="col-12">
                    <select
                      name="package"
                      id="package"
                      value={formData.package}
                      onChange={handleChange}
                      className={`form-control ${formErrors.package ? 'is-invalid' : ''}`}
                    >
                      <option value="">Select a Package</option>
                      <option value="Bronze">Bronze</option>
                      <option value="Silver">Silver</option>
                      <option value="Gold">Gold</option>
                      <option value="Platinum">Platinum</option>
                    </select>
                    {formErrors.package && <div className="invalid-feedback">{formErrors.package}</div>}
                  </div>
                </div>
              )}

              {showFields.comments && (
                <div className="form-group row">
                  <label htmlFor="comments" className="col-sm-2 col-form-label">Comments</label>
                  <div className="col-12">
                    <textarea
                      name="comments"
                      id="comments"
                      value={formData.comments}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Your questions/comments..."
                    ></textarea>
                  </div>
                </div>
              )}

              <div className="form-group row">
                <div className="col-12 mt-2">
                  <ReCAPTCHA
                    sitekey='6LdiEMcUAAAAAEp8xqgc2DCZ1nesdCicB1ofPpSR'
                    onChange={handleRecaptchaChange}
                    onExpired={() => setIsCaptchaVerified(false)}
                  />
                  {formErrors.captcha && <div className="text-danger mt-2">{formErrors.captcha}</div>}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-12">
                  <label htmlFor="submit" className="col-sm-2 col-form-label"></label>
                  <button type="submit" className="btn btn-primary btn-block">
                    {!sendLoading &&
                      <>
                        <span>Submit</span>
                      </>
                    }
                    {sendLoading &&
                      <>
                        <span style={{ paddingRight: '10px' }}>Submit</span>
                        <i className="fa fa-spinner fa-spin " />
                      </>
                    }
                  </button>
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );

};


export default ContactForm;
