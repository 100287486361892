import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DynamicTabs from './DynamicTabs';
import { ApiCall } from '../ApiCall';
import { useParams, Link } from 'react-router-dom';
import FormattedDate from '../DataManipulation/FormatDate';
import FormattedCurrency from '../DataManipulation/FormatCurrency';
import { Account } from '../../Redux/customerSlice';
import Tracking from '../../components/Tracking';
import Loading from '../loading';
import { FaPrint } from 'react-icons/fa';

import '../../styles/Orders.scss';

function Order({ OrderType }) {
  const dispatch = useDispatch();
  const customerAccounts = useSelector((state) => state?.customer?.accounts);
  const showNavbar = useSelector((state) => state?.siteWideSettings?.showNavbar);
  const { RowGuid } = useParams()
  const [Order, setOrder] = useState([]);
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [BillTo, setBillTo] = useState([]);
  const [ShipTo, setShipTo] = useState([]);
  const [AP, setAP] = useState([])
  const [Purchaser, setPurchaser] = useState([])
  const [SalesLines, setSalesLines] = useState([])
  const [Payments, setPayments] = useState([])
  const [trackingData, setTrackingData] = useState([])
  const [adjustedShipping, setAdjustedShipping] = useState(0);
  const [showFreeShipping, setShowFreeShipping] = useState(false);
  const [adjustedSubtotal, setAdjustedSubtotal] = useState(0);
  const [adjustedSalesTax, setAdjustedSalesTax] = useState(0);

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      var data = {
        'TransID': null,
        'RowGuid': RowGuid
      };
      const response = await ApiCall('tq_Order_Invoice', data, 'Tcp');
      if (!response?.data?.Table?.length) {
        setHasAccess(false);
        setLoading(false);
        return;
      }

      setOrder(response.data.Table);

      const orderCustId = response.data.Table[0].CustID;
      const AccountFound = customerAccounts?.find(account => account.CustID === orderCustId);
      setHasAccess(!!AccountFound);

      if (AccountFound) {
        dispatch(Account(AccountFound));
        setBillTo(response.data.Table1 || []);
        setShipTo(response.data.Table2 || []);
        setAP(response.data.Table3 || []);
        setPurchaser(response.data.Table4 || []);
        setSalesLines(response.data.Table5 || []);
        setPayments(response.data.Table6 || []);
        setTrackingData(response.data.Table7 || []);
      }
      setLoading(false);
    };
    fetchOrder();
  }, [RowGuid, customerAccounts, dispatch]);

  useEffect(() => {
    // Handle free shipping adjustments
    const freeShippingLines = SalesLines.filter(line =>
      ['FREE SHIPPING CREDIT', 'FREE SHIPPING DISCOUNT'].includes(line.Description)
    );

    let newShipping = Order[0]?.Shipping || 0;
    if (freeShippingLines.length > 0) {
      const freeShippingCredit = parseFloat(freeShippingLines[0].UnitPrice);
      if (freeShippingCredit < 0) {
        newShipping += freeShippingCredit;
      }
      setShowFreeShipping(newShipping === 0);
    } else {
      setShowFreeShipping(false);
    }
    setAdjustedShipping(newShipping);

    // Handle sales tax adjustments
    const salesTaxLine = SalesLines.find(line => line.Description === 'SALES TAX');
    if (salesTaxLine) {
      const salesTax = parseFloat(salesTaxLine.UnitPrice);
      const newSubtotal = (Order[0]?.SubTotal || 0) - salesTax;
      setAdjustedSubtotal(newSubtotal);
      setAdjustedSalesTax(salesTax);
    } else {
      setAdjustedSubtotal(Order[0]?.SubTotal || 0);
      setAdjustedSalesTax(Order[0]?.SalesTax || 0);
    }
  }, [Order, SalesLines]);

  const getBackButtonText = () => {
    switch (OrderType) {
      case 'Invoices':
        return 'Back To Invoices';
      case 'UnpaidInvoices':
        return 'Back To Unpaid Invoices';
      case 'Orders':
      default:
        return 'Back To Orders';
    }
  };


  return (
    <>
      {showNavbar && (
        <DynamicTabs />
      )}
      <div className={`container ${!showNavbar ? 'pt-4' : ''}`}>
        {loading ? (
          <div className="row">
            <div className="col-md-12">
              <Loading />
            </div>
          </div>
        ) : !hasAccess ? (
          <div className="row">
            <div className="col-md-12">
              <h3>Invoice Access Restricted</h3>
              <p>Your email address is not associated with this invoice. If you believe you should have access to this invoice, please contact your sales specialist for assistance.</p>
            </div>
          </div>
        ) : (
          <>
            {showNavbar && (
              <div className='row'>
                <div className='col-md-12 d-flex align-items-center justify-content-between mb-2'>
                  <div>
                    <Link to={`/customer/${OrderType}`} className='btn btn-outline-primary me-2'>
                      {"<"}
                    </Link>
                    <span className='text-theme-primary'>{getBackButtonText()}</span>
                  </div>
                  {/* <Link to={`/customer/print/${RowGuid}`} target="_blank" className="btn btn-outline-primary">
                    <FaPrint /> Print
                  </Link> */}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">Billing For Customer # {BillTo[0]?.CustID}</h5>
                    <div>{BillTo[0]?.CustName}</div>
                    <div>{BillTo[0]?.Address1}</div>
                    <div>{BillTo[0]?.Address2}</div>
                    <div>{BillTo[0]?.City}, {BillTo[0]?.State} {BillTo[0]?.PostalCode}</div>
                    <div>{BillTo[0]?.Country}</div>
                    <div>Phone: {BillTo[0]?.Phone} / Fax: {BillTo[0]?.Fax}</div>
                    <div>Email: {AP[0]?.ApEmail}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">Ship to</h5>
                    <div>{ShipTo[0]?.Attn}</div>
                    <div>{ShipTo[0]?.ShiptoName}</div>
                    <div>{ShipTo[0]?.Address1}</div>
                    <div>{ShipTo[0]?.Address2}</div>
                    <div>{ShipTo[0]?.City}, {ShipTo[0]?.State} {ShipTo[0]?.PostalCode}</div>
                    <div>{ShipTo[0]?.Country}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">Order # {Order[0]?.OrderNum}</h5>
                    <div>Purchaser: {Purchaser[0]?.PurchaserName}</div>
                    <div>Date: {Order[0]?.OrderDate && <FormattedDate date={Order[0]?.OrderDate} dateFormat='MM/dd/yyyy' />}</div>
                    <div>RepID: {Order[0]?.SalesRep}</div>
                    <div>Tax ID: {Order[0]?.TaxID}</div>
                    <div>Shipped: {Order[0]?.ShipVia}</div>
                  </div>
                </div>
              </div>
            </div>

            <br /><br /><br />

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Description</th>
                        <th className="text-end">Unit Price</th>
                        <th className="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {SalesLines.filter(SalesLine =>
                        !['FREE SHIPPING CREDIT', 'FREE SHIPPING DISCOUNT', 'SALES TAX'].includes(SalesLine.Description)
                      ).map((SalesLine) => (
                        <tr key={SalesLine.EntryNum}>
                          <td>{SalesLine.QuantityShipped}</td>
                          <td>
                            <div dangerouslySetInnerHTML={{ __html: SalesLine.Description }} />
                            {SalesLine.AdditionalDescription && (
                              <div dangerouslySetInnerHTML={{ __html: SalesLine.AdditionalDescription }} />
                            )}
                          </td>
                          <td className="text-end"><FormattedCurrency value={SalesLine.UnitPrice} /></td>
                          <td className="text-end"><FormattedCurrency value={SalesLine.Total} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br /><br /><br />
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title">Payment & Shipping Details</h5>
                      {Payments.map((Payment, index) => (
                        <div key={index}>

                          {Payment.Description}
                        </div>
                      ))}
                      <br />
                      <b>Tracking:</b>
                      {trackingData.length > 0 ? (
                        <div className="d-flex flex-wrap">
                          {trackingData.map((track, index) => (
                            <div key={index} className="me-2">
                              <Tracking trackingNumber={track.TrackingNum} />
                              {index < trackingData.length - 1 && ","}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="text-muted">No tracking numbers available</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between">
                      <span>Subtotal:</span>
                      <span className="text-end"><FormattedCurrency value={adjustedSubtotal} /></span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Shipping:</span>
                      <span className="text-end">
                        {showFreeShipping ? (
                          'FREE'
                        ) : (
                          <FormattedCurrency value={adjustedShipping} />
                        )}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Sales Tax:</span>
                      <span className="text-end"><FormattedCurrency value={adjustedSalesTax} /></span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Payments:</span>
                      <span className="text-end"><FormattedCurrency value={Order[0]?.Payments} /></span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Balance:</span>
                      <span className="text-end"><FormattedCurrency value={Order[0]?.Balance} /></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">

                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Order;
