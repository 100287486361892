import React from 'react';
import { NavLink } from 'react-router-dom';

function SearchNav() {
  return (
    <div className="pb-3">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink
            to="/employee/customers"
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            end
          >
            Customer Search
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/employee/users"
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            end
          >
            User Search
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SearchNav;
