import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import App from "./App";
import store from "./Redux/store";

import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery";
import "././styles/main.scss";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </Provider>
);
