import React from 'react';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// HTML to React attribute mapping
const ATTR_MAPPING = {
  'class': 'className',
  'frameborder': 'frameBorder',
  'allowfullscreen': 'allowFullScreen',
  'marginwidth': 'marginWidth',
  'marginheight': 'marginHeight',
  'scrolling': 'scrolling',
  'srcdoc': 'srcDoc',
  'crossorigin': 'crossOrigin',
  'tabindex': 'tabIndex',
  'maxlength': 'maxLength',
  'readonly': 'readOnly',
  'cellspacing': 'cellSpacing',
  'cellpadding': 'cellPadding',
  'rowspan': 'rowSpan',
  'colspan': 'colSpan',
  'for': 'htmlFor',
};

// Convert HTML attributes to React props
const convertAttributes = (attribs) => {
  const { style, ...rest } = attribs;
  const converted = {};

  // Convert style string to object
  const styleObject = style ? 
    style.split(';').reduce((styleAcc, style) => {
      const [property, value] = style.split(':').map(s => s.trim());
      if (property && value) {
        const camelCaseProperty = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        styleAcc[camelCaseProperty] = value;
      }
      return styleAcc;
    }, {}) : {};

  // Convert all other attributes
  Object.entries(rest).forEach(([key, value]) => {
    // Convert to camelCase if it's in our mapping
    const reactKey = ATTR_MAPPING[key.toLowerCase()] || key;
    converted[reactKey] = value;
  });

  return { converted, styleObject };
};

function HtmlImportParser({ content }) {
  const ImageUrl = useSelector((state) => state?.siteWideSettings?.imageServerUrl);

  const options = {
    replace: ({ name, attribs, children }) => {

      // TODO: THis works fine. The only problem is we have too many adds that use the old urls. What happens when we make those into React Router links is that 
      //      they are not redirected by the web.config reqirects since they stay in the SPA. Once we are ready to only use the new urls, we can use this code. 
      // Convert appropriate <a> tags to react-router-dom <Link> tags
      // if (name === 'a' && attribs) {
      //   const { href } = attribs;

      //   if ((href.includes("triplecrownproducts.com/r/") || href.startsWith("/r/")) && !href.includes("#")) { // TODO this will need to be changed from ".../r" once we change the location of the react site
      //     let to = href.replace(/.*triplecrownproducts\.com\/r/, ''); // Remove domain and everything before it
      //     if (to.startsWith('/r/')) {
      //       to = to.substring(2); // Remove the '/r' prefix
      //     }

      //     return (
            
      //       <Link to={to}>
      //         {domToReact(children, options)}
      //       </Link>
      //     );
      //   }
      // }

      if ((name === 'img' || name === 'iframe') && attribs) {
        const { converted, styleObject } = convertAttributes(attribs);

        if (name === 'img') {
          return (
            <LazyLoadImage
              {...converted}
              src={attribs['data-src'] || attribs.src} // TODO: remove data-src attribute from HTML in ads 54-57 after testing and live deployment. i.e. when TcpMobile no longer uses it.
              placeholderSrc={`${ImageUrl}adfiles/15102-TCPLoading_Optimized.gif`}
              style={styleObject}
            />
          );
        } else { // iframe
          return (
            <iframe
              {...converted}
              title={attribs.title || "Embedded Content"}
              style={styleObject}
            />
          );
        }
      }
    }
  }

  return parse(content, options);
}

export default HtmlImportParser;