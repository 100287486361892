import React from "react";
import AELogin from "../../../components/AddEdit/AELogin";
import { useSelector } from "react-redux";

function AEHome() {
  const isStaffLoggedIn = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.LoggedIn);

  return (
    <div className="d-flex justify-content-center mt-5">
      {
        isStaffLoggedIn
          ? (
          <div className="d-flex flex-column align-items-center gap-3">
            <div>SUCCESS: You are logged in</div>
            <div>To log out, please use the button in the footer at the bottom of the page.</div>
          </div>
          )
          : (<AELogin />)
      }
    </div>
  );
}

export default AEHome;
