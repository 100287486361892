import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Account, Accounts } from '../../Redux/customerSlice';
import ProfileCustomer from "../../components/Customer/Profile";
import Orders from "../../components/Customer/Orders";
import Order from "../../components/Customer/Order";
import PaymentMethodCustomer from "../../components/Customer/PaymentMethod";
import SupportCustomer from "../../components/Customer/Support";
import ManageLogosCustomer from "../../components/Customer/ManageLogos";
import CustomerSecurity from "../../components/Customer/Security";
import Preferences from '../../components/Customer/Preferences';
import SavedCarts from '../../components/Customer/SavedCarts';
import TaxExemption from '../../components/Customer/TaxExemption';
import "../../styles/CustomerShell.scss";
import { ApiCall } from '../../components/ApiCall';

function CustomerShell() {
  const dispatch = useDispatch();
  const location = useLocation();
  const Customer = useSelector((state) => state.customer);
  const [selectedAccount, setSelectedAccount] = useState(Customer?.account);

  const pageName = location.pathname.split('/')[2].toLowerCase();
  const [activePage, setActivePage] = useState(pageName || 'profile');

  useEffect(() => {
    const newPageName = location.pathname.split('/')[2].toLowerCase();
    setActivePage(newPageName || 'profile');
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    setSelectedAccount(Customer?.account);
  }, [Customer?.account]);

  useEffect(() => {
    const fetchAccounts = async () => {
      if (!Customer?.accounts || Customer?.accounts.length === 0) {
        var data = {
          'Email': Customer?.email
        }
        const response = await ApiCall('tq_ReturnCustomersForEmail', data, 'Tcp');
        if (response?.data?.Table) {
          await dispatch(Accounts(response.data.Table));
        }
      }
    };

    fetchAccounts();
  }, [Customer?.accounts, Customer?.email, dispatch]);

  const handleAccountChange = (event) => {
    const Selected = event.target.value;
    const AccountFound = Customer?.accounts.filter(account => account.CustID === Selected)[0];
    setSelectedAccount(Selected);
    dispatch(Account(AccountFound));
  };

  return (
    <div className="account-container">
      <div className="menuAndHeader-wrapper">
        <div className="menu">
          {Customer?.accounts && Customer?.accounts.length > 1 && (
            <select value={selectedAccount} onChange={handleAccountChange} className="form-select">
              {selectedAccount === '' && (
                <option value="" disabled>Select Account</option>
              )}
              {Customer?.accounts.map((account) => (
                <option key={account.CustID} value={account.CustID}>{account.CustName}</option>
              ))}
            </select>
          )}
          {Customer?.accounts && Customer?.accounts.length === 1 && (
            <span>{Customer?.accounts[0].CustName}</span>
          )}
        </div>
        <div className="nameHeader">
          {Customer?.firstName ? <h2>Hello, {Customer?.firstName}</h2> : <h2>Welcome Back!</h2>}
        </div>
      </div>
      <div className="pages">
        <div className="customer-account-pages">
          {activePage === "profile" && <ProfileCustomer />}
          {activePage === "taxexemption" && <TaxExemption />}
          {activePage === "orders" && <Orders OrderType='Orders' />}
          {activePage === "order" && <Order OrderType='Orders' />}
          {activePage === "unpaidinvoices" && <Orders OrderType='UnpaidInvoices' />}
          {activePage === "unpaidinvoice" && <Order OrderType='UnpaidInvoices' />}
          {activePage === "invoices" && <Orders OrderType='Invoices' />}
          {activePage === "invoice" && <Order OrderType='Invoices' />}
          {activePage === "paymentmethod" && <PaymentMethodCustomer />}
          {activePage === "support" && <SupportCustomer />}
          {activePage === "managelogos" && <ManageLogosCustomer />}
          {activePage === "security" && <CustomerSecurity />}
          {activePage === "mfapreferences" && <CustomerSecurity />}
          {activePage === "savedcarts" && <SavedCarts />}
          {activePage === "preferences" && <Preferences />} {/* TODO: Create (or eliminate)Preferences component */}
        </div>
      </div>
    </div>
  );
}

export default CustomerShell;