import { useParams, useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ApiCall } from '../../../components/ApiCall';
import Loading from '../../../components/loading';

function Customer() {
  const { CustID } = useParams();
  const [customer, setCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: 'all',
  });

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        var data = {
          'CustID': CustID,
          'WebsiteID': 1
        }
        const response = await ApiCall('usp_AE_Customers', data, 'tcp');
        if (response?.data?.Table?.[0]) {
          setCustomer(response.data.Table[0]);
          setUsers(response.data.Table1);
        }
      } catch (error) {
        console.error('Error fetching customer:', error);
      }
    };

    if (CustID) {
      fetchCustomer();
    }
  }, [CustID]);

  useEffect(() => {
    console.log('users', users);
  }, [users]);

  const filteredUsers = Array.from(new Set(users.map(user => user.WebsiteUserID)))
    .map(id => users.find(user => user.WebsiteUserID === id))
    .filter(user => {
      const matchesSearch = searchTerm === '' ||
        Object.values(user)
          .join(' ')
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

      const matchesStatus = filters.status === 'all' ? true : false;

      return matchesSearch && matchesStatus;
    });

  return (
    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Link
          to="/employee/customers"
          className="btn btn-outline-primary"
        >
          Back to Customers
        </Link>
      </div>
      {customer ? (
        <>
          <h1 className="mb-4">{customer.CustName}</h1>

          <ul className="nav nav-tabs mb-4" id="customerTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#users"
                type="button"
                role="tab"
                aria-controls="users"
                aria-selected="true"
              >
                Users
              </button>
            </li>
          </ul>

          <div className="tab-content" id="customerTabsContent">
            <div
              className="tab-pane fade show active"
              id="users"
              role="tabpanel"
              aria-labelledby="users-tab"
            >
              <div className="row mb-4">
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <select
                    className="form-select"
                    value={filters.status}
                    onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                  >
                    <option value="all">All Status</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </div>
              </div>

              <table className="table">
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Reset Password</th>
                    <th>Email Confirmed</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers && filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => (
                      <tr key={user.WebsiteUserID}>
                        <td>{user.WebsiteUserID}</td>
                        <td>{[user.FirstName, user.LastName].filter(Boolean).join(' ')}</td>
                        <td>{user.Email}</td>
                        <td>{user.Active}</td>
                        <td>{user.ResetPassword ? 'Yes' : 'No'}</td>
                        <td>{user.EmailConfirmed ? 'Yes' : 'No'}</td>
                        <td>
                          {user.WebsiteUserID && user.WebsiteUserID !== 0 ? (
                            <Link
                              to={`/employee/user/${user.WebsiteUserID}/${CustID}`}
                              className="btn btn-primary btn-sm"
                            >
                              View
                            </Link>
                          ) : (
                            <Link
                              to={`/customer/signup/${user.RowGuid}`}
                              className="btn btn-success btn-sm"
                            >
                              Create
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No users found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
      {showCreateConfirmation && (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create New User</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowCreateConfirmation(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>Create new user account for {selectedUserEmail}?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowCreateConfirmation(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    navigate(`/employee/user/create/${selectedUserEmail}`);
                    setShowCreateConfirmation(false);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Customer;

