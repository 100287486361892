import React from 'react';
import { ApiCall } from '../ApiCall';
import packageJson from '../../../package.json';

export async function LogError(error, errorGroup, componentName) {
  const websiteVersion = packageJson.version;
  const data = {
    ErrorGroup: errorGroup,
    Host: window.location?.hostname,
    ApplicationName: 'TCP React',
    UserId: '',
    ClientIP: '',
    URL: window.location.href,
    ErrorMessage: error?.message,
    ErrorStack: error?.stack,
    WebOrderID: null,
    WebOrderRowGuid: '',
    AppVersion: websiteVersion,
    Page: componentName || ''
  }
  
  const response = await ApiCall('usp_LogError', data, null, null, null, false);
}

