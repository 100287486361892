import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiCall } from '../../../components/ApiCall';
import Loading from '../../../components/loading';
import Paging from '../../../components/Paginations';
import SearchNav from '../../../components/Employee/AE/SearchNav';

function Users() {
  const [Customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = async () => {
    setIsLoading(true);
    if (!searchTerm.trim()) {
      setCustomers([]);
      setIsLoading(false);
      return;
    }

    const data = {
      'Search': searchTerm
    };
    const response = await ApiCall('usp_AE_Customers', data, 'tcp');
    setCustomers(response?.data?.Table || []);
    setIsLoading(false);
  };

  const indexOfLastCustomer = currentPage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  const currentCustomers = Customers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchUsers();
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  return (
    <div className="container-fluid py-4">
      <SearchNav />
      <div className="row mb-4">
        <div className="col-md-8">
          <div className="input-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Search Customers / Customer ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="btn btn-outline-primary" type="button">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-muted fw-medium me-2">Show:</label>
            <select
              className="form-select form-select-lg"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              <option value={100}>100</option>
              <option value={250}>250</option>
              <option value={500}>500</option>
            </select>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : searchTerm.trim() ? (
        <>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>Customer Name</th>
                  <th>Address 1</th>
                  <th>Address 2</th>
                  <th>City</th>
                  <th>Region</th>
                  <th>Country</th>
                  <th>Postal Code</th>
                  <th>Phone</th>
                  <th>Sales Rep 1</th>
                  <th>Sales Rep 2</th>
                  <th>Credit Hold</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentCustomers.length > 0 ? (
                  currentCustomers.map(customer => (
                    <tr key={customer.CustId}>
                      <td>{customer.CustId}</td>
                      <td>{customer.CustName}</td>
                      <td>{customer.Addr1}</td>
                      <td>{customer.Addr2}</td>
                      <td>{customer.City}</td>
                      <td>{customer.Region}</td>
                      <td>{customer.Country}</td>
                      <td>{customer.PostalCode}</td>
                      <td>{customer.Phone}</td>
                      <td>{customer.SalesRepId1}</td>
                      <td>{customer.SalesRepId2}</td>
                      <td>{customer.CreditHold ? 'Yes' : 'No'}</td>
                      <td>
                        <Link
                          to={`/employee/customer/${customer.CustId}`}
                          className="btn btn-primary"
                        >
                          View/Edit
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13" className="text-center">No customers found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {Customers.length > itemsPerPage && (
            <Paging
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={Customers.length}
              handlePageChange={handlePageChange}
            />
          )}
        </>
      ) : (
        <div className="text-center mt-4">
          <p className="text-muted">Enter a search term to view customers</p>
        </div>
      )}
    </div>
  );
}

export default Users;
